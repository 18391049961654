import React from 'react'
import { graphql } from 'gatsby'
import NotFoundPage from 'components/NotFoundPage'

export default function NotFound(props) {
    return typeof window !== 'undefined' && window && <NotFoundPage {...props}/>
}

export const query = graphql`
query getGetInTouchPageQuery($language: String!) {
  pageData: allMdx(filter: {fields: {slug: {eq: "/404"}}}) {
    nodes {
      body
      id
      internal {
        contentFilePath
      }
      headings {
        depth
        value
      }
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        description
        showCTA
        seoTitle
        seoDescription
        seoimage
        domain
        email
      }
      parent {
        ... on GoogleDocs {
          id
          images {
            name
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          images2: images {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(width: 265, height: 265)
            }
          }
        }
      }
    }
  }
  locales: allLocale(
    filter: {language: {eq: $language}, ns: {in: ["404", "common"]}}
  ) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}

`
