import React, { useEffect, useState } from 'react'
import useiCloudReady from '../../hooks/useiCloudReady'
import { CallToAction } from '../CallToAction'
import Layout from '../Layout'
import Lottie from 'react-lottie'
import { StaticImage } from 'gatsby-plugin-image'
import SearchBox from 'components/Search/SearchBox'
import { useLayoutData } from 'hooks/useLayoutData'

export default function NotFoundPage({ data, location, pageContext, children }): JSX.Element {
    const icloudready = useiCloudReady()
    const [readyData, setReadyData] = useState<any | null>(null)
    const { pageData, locales } = data
    const {
        body,
        excerpt,
        frontmatter,
        parent,
        fields: { slug },
    } = pageData?.nodes[0]
    const {
        title,
        subtitle,
        description,
        sidebar,
        pageStyle,
        darkMode,
        hideSurvey,
        seoTitle,
        seoDescription,
        seoimage,
        email,
        domain,
    } = frontmatter
    useEffect(() => {
        import('../../../static/animations/astroready2.json').then((data) => setReadyData(data.default))
        icloudready?.capture('page_404')
    }, [])

    return (
        <Layout className="not-found-page-container">
            <div className="bg-blue">
                <div className="relative mx-auto max-w-6xl overflow-hidden px-4 py-24 text-white lg:px-8 xl:px-0">
                    <StaticImage
                        src="../../images/galaxy-1.png"
                        alt="The stars in the sky"
                        placeholder="blurred"
                        className="!absolute top-0 -left-24 max-h-full"
                    />
                    <StaticImage
                        src="../../images/galaxy-2.png"
                        alt="More stars in the sky"
                        placeholder="blurred"
                        className="!absolute top-0 -right-8 max-h-full"
                    />

                    <div className="right-0 bottom-0 -mt-12 h-[400px] w-[400px] sm:!absolute sm:-right-12 sm:mt-0 sm:h-[500px] sm:w-[500px] md:-bottom-28 lg:-right-24 lg:h-[600px] lg:w-[600px]">
                        {readyData ? (
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: readyData,
                                }}
                            />
                        ) : null}
                    </div>
                    {/* (
                            <StaticImage
                                src="../../images/astroready.gif"
                                alt="Space ready"
                                placeholder="blurred"
                                className="w-[250px] sm:w-[500px] rotate-12"
                            />
                        ) */}
                    <div className="-mt-12 mb-4 text-[15px] opacity-75 sm:mt-0">
                        <strong>404:</strong> Page not found
                    </div>
                    <h2 className="mb-0 text-5xl text-white md:text-7xl">Lost in space</h2>

                    <div className="relative sm:w-1/2 md:w-3/4">
                        <div className="py-8">
                            <h3 className="mb-4 text-2xl text-yellow">Try a search to beam back to {domain}:</h3>
                            <SearchBox placeholder="Search..." location="404" />
                            <p className="max-w-lg pt-2 text-sm opacity-75">
                                Searches: Docs, API, Tutorials, Blog and Support Questions –{' '}
                                <em>and it's actually pretty good!</em>
                            </p>
                        </div>

                        <CallToAction type="secondary" width="84" to="/">
                            Take me back to the homepage
                        </CallToAction>

                        <p className="mt-8 text-sm text-white/70">
                            Think this is a mistake? Email <a href={`mailto:${email}`}>{email}</a> and we'll fix it!
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
